<template>
  <header-line></header-line>
  <div class="content" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <div class="cart__page">
        <div class="cart__header">
          <div class="title">
            <svg class="icon">
              <use xlink:href="#i-cart"></use>
            </svg>
            {{ t('label.my_cart') }}:
          </div>
          <div class="quantity">
            {{ t('message.item', {n: cartItemsCount}) }}
          </div>
        </div>
        <div class="cart__list">
          <div class="cart__item" v-for="cartItem in cartItems" v-bind:key="cartItem.data.voucher.id">
            <div class="cart__card card card--small">
              <figure class="card__image">
                <router-link v-bind:to="{name: 'product', params: {id: cartItem.data.voucher.id, lang}}" class="'card__link--image'">
                  <img
                      v-if="cartItem.data.voucher.image_1x"
                      v-bind:src="cartItem.data.voucher.image_1x.src"
                      v-bind:alt="cartItem.data.voucher.name"
                      v-bind:srcset="cartItem.data.voucher.image_2x.src +' 2x, ' + cartItem.data.voucher.image_3x.src + ' 3x'"
                  >
                </router-link>
              </figure>
              <div class="card__block">
                <div class="card__title">
                  {{ cartItem.data.voucher.name }}
                </div>
                <span class="card__options" v-if="cartItem.data.option">
                  {{ cartItem.data.option }}
                </span>
<!--                <div class="card__description" v-html="cartItem.data.voucher.description"></div>-->
                <div class="card__btns card__btns--mobile">
                  <button class="btns btn-delete" v-on:click.prevent="removeItem(cartItem.data)">
                    <svg class="icon i24x24"><use xlink:href="#i-delete"></use></svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="card__btns card__btns--desktop">
              <button class="btns btn-delete" v-on:click.prevent="removeItem(cartItem.data)">
                <svg class="icon i24x24"><use xlink:href="#i-delete"></use></svg>
              </button>
            </div>
            <div class="cart__quantity">
              <div class="label">{{ t('label.quantity') }}</div>
              <div class="inputs">
                <button class="btns btn-minus" v-on:click.prevent="decreaseQuantity(cartItem.data)">
                  <i class="icons i24x24 i-minus"></i>
                </button>
                <input class="quantity__input" type="text" name="quantity" v-bind:value="cartItem.count"/>
                <button class="btns btn-plus active" v-on:click="increaseQuantity(cartItem.data)">
                  <i class="icons i24x24 i-plus"></i>
                </button>
              </div>
            </div>
            <div class="cart__value">
              <div class="label">{{ t('label.value') }}</div>
              <div class="text">{{ numberFormat(getCartItemPrice(cartItem, true), data.currencySymbol) }}</div>
            </div>
          </div>
        </div>
        <div class="cart__summ">
          <span class="label">{{ t('label.total_price') }}:</span>
          {{
            numberFormat(
              getCartTotalPrice(cartItems) - (data.pricesTaxInclusive ? 0 : getCartRetailTotalTax(cartItems)),
              data.currencySymbol
            )
          }}
        </div>
        <div class="cart__btns">
          <router-link v-bind:to="{name: 'shop'}" class="btns btn-white" tag="button">
            {{ t('button.continue_shopping') }}
          </router-link>

          <router-link class="btns btn-orange" v-if="cartItemsCount > 0"
                       v-bind:to="{name: 'checkout'}"
          >
            {{ t('button.checkout') }}
          </router-link>
          <a href="#" v-else class="btns btn-orange disabled">{{ t('button.checkout') }}</a>
        </div>
      </div>
    </div>
  </div>
  <footer-line></footer-line>
</template>

<script>
import {useStore} from 'vuex'
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'
import {numberFormat} from '@/helpers/PriceHelper'
import HeaderLine from "@/components/HeaderLine";
import FooterLine from "@/components/FooterLine";
import {ACTION_TYPES, MUTATION_TYPES} from "@/constants";
import usePrice from "@/helpers/usePrice";

export default {
  name: 'cart',
  components: {FooterLine, HeaderLine},
  setup() {
    const store = useStore();
    const cartItems = computed(() => store.getters.groupedCartItems);
    const cartItemsCount = computed(() => store.getters.cartItemsCount);
    const data = computed(() => store.getters.data);
    const {getCartItemPrice, getCartTotalPrice, getCartRetailTotalTax} = usePrice();

    const removeItem = (item) => {
      store.commit(MUTATION_TYPES.removeItemFromCart, {id: item.voucher.id, 'option': item.option})
    }
    const increaseQuantity = (item) => store.dispatch(ACTION_TYPES.addItemToCart, item);
    const decreaseQuantity = (item) => store.commit(
        MUTATION_TYPES.decreaseItemCount, {id: item.voucher.id, option: item.option}
    );

    const i18n = useI18n();

    return {
      t: i18n.t,
      cartItems,
      cartItemsCount,
      removeItem,
      getCartItemPrice,
      numberFormat,
      increaseQuantity,
      decreaseQuantity,
      getCartTotalPrice, getCartRetailTotalTax,
      data
    }
  }
}
</script>

<style>
  .card__title--option {
    font-size: 16px;
  }
</style>